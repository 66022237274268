.EditorSidebar {
  border-right: 1;
  border-color: black;
  background-color: #c8a063;
}

.EditorSidebar .MuiTabs-indicator {
  /* the vertical bar indicating the selected tab */
  background-color: #80642a;
  width: 4px;
}

.EditorSidebarTab {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
}

.EditorSidebar .MuiTouchRipple-root {
  /* the ripple effect if you click on a tab */
  color: #80642a;
}
