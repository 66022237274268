.EditorIconPicker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 24rem;
  margin: 1rem;
}

.EditorIconPicker svg {
  width: 3rem;
  height: 3rem;
}
