.UndoButton {
  position: absolute !important;
  top: 0;
  right: 0;
  transform: translate(35%, -10%);
  background-color: #c8a063 !important;
  color: white !important;
  z-index: 10;
  width: 1.75rem;
  height: 1.75rem;
}
