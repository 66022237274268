.EditorHeader {
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  gap: 1rem;
  margin-bottom: 1rem;
}

.EditorHeaderSummary {
  min-width: 0;
  flex-grow: 1;
  margin: 1rem 0 !important;
}

.EditorHeaderSummary p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.EditorHeaderCorner {
  flex-grow: 0;
  flex-shrink: 0;
}

.EditorHeaderCorner > .MuiButton-root {
  background-color: #c8a063;
}

.EditorHeaderCorner > .MuiButton-root:hover {
  background-color: #aa7942;
}

.EditorHeader .MuiChip-root {
  color: white;
  background-color: #c8a063;
}

.EditorHeader .MuiChip-root:hover {
  background-color: #aa7942;
}

.EditorHeader .MuiChip-deleteIcon {
  color: white !important;
}
