.EditColumn {
  width: 2.5%;
}
.DetailsColumn {
  width: 2.5%;
}
.AcceptanceRateColumn {
  width: 7.5%;
}
.VenueNameColumn {
  width: 20%;
}
.PlanNameColumn {
  width: 40%;
}
.PriceTypesColumn {
  width: 20%;
}
.PublishedDateColumn {
  width: 7.5%;
}
.DragHandleColumn {
  width: 2.5%;
}

.DndTable {
  border-collapse: separate !important;
}

.DndTable .MuiTableCell-root {
  padding: 0.25rem 0.25rem !important;
}
