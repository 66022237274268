.review-list-datagrid {
  background-color: #fff;
}

.review-list-datagrid .link-cell {
  overflow: hidden;
  text-overflow: ellipsis;
}

.review-list-datagrid .link-cell a {
  color: #000;
}

.review-list-datagrid .review-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  cursor: pointer;
}

.review-list-paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 75vw;
  max-height: 75vh;
  overflow: auto;
  padding: 2rem;
}

.review-list-paper * {
  white-space: pre-line;
}

.review-list-datagrid .column-header {
  font-weight: 500;
  align-items: center;
  display: flex;
}
