.info-bar-modal-paper {
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vw;
  max-height: 75vh;
  overflow: auto;
  background-color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.info-bar-modal-paper hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.info-bar .synopsis-button {
  width: 100%;
  color: white;
  margin-bottom: 0.1rem;
  border-radius: 0;
  opacity: 0.85;
}

.info-bar .synopsis-button:hover {
  opacity: 1;
}

.info-bar .synopsis-button.announcement {
  background-color: #4b79c0;
}

.info-bar .synopsis-button.warning {
  background-color: #eb4034;
}

.info-bar .synopsis-button-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media screen and (min-width: 1200px) {
  .info-bar .synopsis-button-content {
    width: 1200px;
  }
}

.info-bar .synopsis-button-content svg {
  margin-right: 1rem;
}
