.login-container {
  text-align: center;
}

.login-logo-div {
  margin-bottom: 30px;
}

.login-logo-text {
  font-weight: bold;
  font-size: 12px;
}

.login-field {
  background-color: #fbfbfb;
}

.login-field-div {
  margin-bottom: 7px;
}
