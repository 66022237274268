.Revertable {
  position: relative;
  padding: 0.65rem 0 0.65rem 0;
}

.Revertable:not(:last-child) {
  margin-bottom: 0.65rem;
}

/* add a border around an array item */
.RevertableArray.Touched {
  border: 0.1rem solid rgba(201, 160, 100, 50);
  border-radius: 0.5rem;
}

/* add an invisible border if untouched so box model takes border into account */
.RevertableArray {
  border: 0.1rem solid rgba(0, 0, 0, 0);
}

/* add a border around a non-array textfield */
.Revertable.Touched:not(.RevertableArray) .MuiOutlinedInput-notchedOutline {
  border-width: 0.12rem;
  border-color: rgba(201, 160, 100, 50);
}
