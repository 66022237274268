.EditorImageUploader {
  aspect-ratio: 1/1;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 8rem;
}

.EditorImageUploader.NoImage {
  background: repeating-linear-gradient(
    45deg,
    #efefef,
    #efefef 10px,
    white 10px,
    white 20px
  );
}

.EditorImageUploader.NoImage.Mutable {
  background: repeating-linear-gradient(
    45deg,
    #f2ce97,
    #f2ce97 10px,
    white 10px,
    white 20px
  );
}

.EditorFaqImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: all 0.3s ease;
  position: absolute;
}

.EditorImageUploader:hover .EditorFaqImage {
  filter: opacity(55%) blur(0.35rem);
}

.EditorFaqImageAddButton,
.EditorFaqImageEnlargeButton,
.EditorFaqImageDeleteButton {
  z-index: 10;
  transition: all 0.3s ease;
}

.EditorImageUploader:hover .EditorFaqImageEnlargeButton,
.EditorImageUploader:hover .EditorFaqImageDeleteButton {
  box-shadow: 0 0 10px black;
}

.EditorFaqImageEnlargeButton > .MuiSvgIcon-root,
.EditorFaqImageDeleteButton > .MuiSvgIcon-root {
  opacity: 0%;
  transition: all 0.3s ease;
  width: 2rem;
  height: 2rem;
}

.EditorFaqImageAddButton > .MuiSvgIcon-root {
  width: 4rem;
  height: 4rem;
}

.EditorImageUploader:hover .EditorFaqImageEnlargeButton > .MuiSvgIcon-root,
.EditorImageUploader:hover .EditorFaqImageDeleteButton > .MuiSvgIcon-root {
  opacity: 1;
}

.EditorFaqImutableIcon {
  width: 4rem !important;
  height: 4rem !important;
  z-index: 10;
  transition: all 0.3s ease;
  opacity: 50%;
}
