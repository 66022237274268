.EditorPaper {
  overflow: hidden;
}

.EditorSidebar {
  flex-grow: 0;
  flex-shrink: 0;
}

.Editor {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  max-height: 9999px;
  transition: max-height 0.5s;
}

.EditorContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 1rem;
  min-width: 0;
}

.EditorBody {
  margin-bottom: 1rem;
}

.EditorSkeleton > * {
  margin-bottom: 1rem;
}
