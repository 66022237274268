a.PlanDashboardLink {
  color: inherit;
  text-decoration: none;
}

.AcceptanceRateField {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AcceptanceRateField.LowAcceptanceRate {
  color: #ff4a4a;
}

.AcceptanceRateField.LowAcceptanceRate.HasMinBookings {
  font-weight: 900;
}
