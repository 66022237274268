.SortableRow {
  background-color: white;
  z-index: 0;
  position: relative;
}

.ActiveRow {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.SortableRow ul {
  margin: 0;
}
