.VenueMenu {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
  float: right;
}

.VenueMenu > * {
  margin-left: 1rem;
}
