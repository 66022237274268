.BasicInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
}

.BasicInfo > * {
  flex-grow: 1;
  min-width: 45%;
}

.BasicInfoForm {
  display: flex;
  flex-direction: column;
}

.BasicInfoForm > div:not(:last-child) {
  margin-bottom: 0.65rem;
}
