.DashboardItemPaper {
  padding: 1rem;
}

.DashboardItemHeader {
  display: flex;
  align-items: center;
}

.DashboardItemSkeleton {
  width: 100%;
  height: 22.5rem !important;
}
