.InclusionCard {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.InclusionCard svg {
  width: 1.5rem;
  height: 1.5rem;
}

.InclusionCard:not(:last-child) {
  margin-bottom: 2rem;
}
