.OptionGroup {
  font-weight: bold;
  font-style: italic;
}

.DashboardScopeSelector {
  margin-bottom: 1rem;
}

.DashboardScopeSelectorSkeleton {
  height: 2.25rem !important;
}

.DashboardScopeSelectorButton {
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.DashboardScopeSelectorItem {
  flex-grow: 1;
}

.DashboardScopeSelectorItem,
.DashboardScopeSelectorPlanItem,
.DashboardScopeSelectorVenueItem {
  overflow: hidden;
  text-overflow: ellipsis;
}

.DashboardScopeSelectorVenueItem,
.DashboardScopeSelectorPlanItem {
  display: list-item !important;
}

.DashboardScopeSelectorVenueItem {
  font-weight: 600 !important;
}

.DashboardScopeSelectorPlanItem {
  margin-left: 1rem !important;
}
