.EditorHighlightCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.EditorHighlightCard .EditorImageUploader {
  margin-right: 1rem;
  flex: 0 1 100%;
  max-width: 10.5rem;
}

.EditorHighlightCardInput {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 1rem;
}

.EditorHighlightCardInput > .MuiTextField-root:first-child {
  margin-bottom: 1rem !important;
}
