.lightbox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vmin;
  aspect-ratio: 4/3;
}

.lightbox .slider-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  border-radius: 2rem;
}

.lightbox .image-reel {
  display: flex;
  height: 100%;
}

.lightbox .image-backdrop {
  height: 100%;
  background-color: black;
  aspect-ratio: 4/3;
  scroll-snap-align: start;
}

.lightbox .image-backdrop img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lightbox .interface-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
}

.lightbox .interface-button {
  border-radius: 0;
  opacity: 0.2;
}

.lightbox .interface-button:hover {
  opacity: 0.8;
}

.lightbox .interface-button-icon {
  color: white;
  font-size: 5rem;
}

.lightbox .image-selector-list {
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);
  border-radius: 2rem;
  opacity: 0.8;
}

.lightbox .image-selector {
  color: white;
  font-size: 1.5rem;
}
