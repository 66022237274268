.EditorPaidItemCard {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.EditorPaidItemCardInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.EditorPaidItemCard .EditorImageUploader {
  margin: 0 1rem 0 0.5rem;
  flex: 0 1 100%;
  max-width: 11rem;
}

.EditorPaidItemCard > .MuiTextField-root {
  flex-grow: 1;
  margin-right: 1rem;
}

.EditorPaidItemCardContainer {
  background-color: white;
  border-radius: 1rem;
}
.EditorPaidItemCardContainer:not(:last-child) {
  margin-bottom: 1rem;
}
